export const en: Record<string, string> = {
    intro_header: 'Get help now',
    intro_subheader:
        'See an NHS GP by video within minutes. Just a few steps away.',
    intro_start_button: 'See an NHS GP now',

    signup_form_header:
        'So we can provide you with the best care, please complete the details below.',
    signup_field_fullname: 'Full name',
    signup_field_dob: 'Date of birth (YYYYMMDD)',
    signup_field_postcode: 'Post code',
    signup_field_address: 'Address',
    signup_field_gp_name: 'Name of your registered GP',
    signup_field_practice_name: 'Name of your GP Practice',
    signup_field_token: 'Token',
    signup_submit_button: 'See an NHS GP now',
    signup_terms_toc_links:
        'By continuing you accept our <tocLink>terms and conditions</tocLink> and confirm you have read our <privacyLink>privacy notice</privacyLink>',
    signup_generic_error:
        "Sorry, something wasn't right. Please check you information and try again.",
    signup_validation_empty: 'This field seems to be missing',
    signup_validation_date: 'The date seems to be in the wrong format',

    meeting_header: 'Your consultation with a GP will start soon',
    meeting_subheader:
        'Find a quiet and private place to sit, and make sure your phone is charged.',
    meeting_countdown_header: 'Your GP will appear here in:',
    meeting_countdown_shortly: 'less than two minutes',
    meeting_countdown_minutes: 'in {minutes} minutes',
    meeting_countdown_unknown: 'Connecting you to a LIVI GP...',
    meeting_connecting: 'Connecting you to a LIVI GP...',
    meeting_incoming_call_header: 'A GP is calling you',
    meeting_accept_call_button: 'Accept call',

    done_header: 'Your consultation has ended',
    done_subheader: 'Thank you for using LIVI',
    done_read_more_link: 'Read more about <link>Covid-19 (Coronavirus)</link>',

    unsupported_browser_header: 'Your browser is not supported',
    unsupported_browser_subheader:
        "We're sorry. Our video calls don't work in this internet browser. Copy the SMS link and try opening it in a different browser."
};
