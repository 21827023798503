/** @jsx jsx */
import React, { FC, Fragment } from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { colors } from '../../constants/colors';
import liviLogo from '../../assets/livi_logo.svg';

const BUTTON_STYLE: CSSObject = {
    display: 'block',
    width: '100%',
    height: 50,
    backgroundColor: colors.PRIMARY,
    borderRadius: 7,
    border: 'none',
    color: '#fff',
    fontWeight: 700,
    ':disabled': {
        opacity: 0.7
    }
};

const DECORATION_STYLE: CSSObject = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
    color: colors.MEDIUM_GREY,
    textTransform: 'uppercase',
    fontSize: 13,
    textAlign: 'center',
    img: {
        height: 16,
        marginLeft: 8
    }
};

const Button: FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = props => {
    return (
        <Fragment>
            <button {...props} css={BUTTON_STYLE}></button>
            <div css={DECORATION_STYLE}>
                Powered by
                <img src={liviLogo} alt='LIVI logo' />
            </div>
        </Fragment>
    );
};

export default Button;
