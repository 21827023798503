import React from 'react';
import ReactDOM from 'react-dom';

import { AppContextProvider } from './context/appContext';
import { IntlProvider } from 'react-intl';
import { en } from './intl/translations';

import { Global } from '@emotion/core';
import globalStyles from './utils/globalStyles';
import WebFontLoader from 'webfontloader';

import App from './App';

WebFontLoader.load({
    google: {
        families: ['Montserrat:400,500,700']
    }
});

export const renderWithComponent = (Component: React.FC<any>, element: HTMLElement) => {
    ReactDOM.render(
        <AppContextProvider>
            <IntlProvider locale={'en'} messages={en}>
                <Global styles={globalStyles} />
                <Component />
            </IntlProvider>
        </AppContextProvider>,
        element
    );
}

export const render = (element: HTMLElement) => {
    renderWithComponent(App, element);
}
