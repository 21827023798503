// Twillio supports WebRTC enabled browsers, so this should give us
// a pretty decent browser support check.

const isWebRTCSupported = () => {
    const isRTCPeerConnectionSupported = () => {
        return (
            typeof window.RTCPeerConnection !== 'undefined' ||
            typeof window.webkitRTCPeerConnection !== 'undefined'
        );
    };

    const isGetUserMediaSupported = () => {
        return (
            !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||
            !!window.getUserMedia
        );
    };

    return isGetUserMediaSupported() && isRTCPeerConnectionSupported();
};

export default isWebRTCSupported;
