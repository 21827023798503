import request from '../utils/request';
import Cookies from 'js-cookie';
import { IFormFieldValues } from '../hooks/useForm';
import { ISessionStatus } from '../context/appContext';

const API_HOST =
    window.location.protocol + '//' + window.location.host.replace('', ''); // replace('nhs.', '')

export interface ISubmitSuccessResponse {
    sessionId: string;
}

export type ISubmitErrorStatus =
    | 'token_consumed'
    | 'token_not_found'
    | 'token_expired'
    | 'invalid_age'
    | 'invalid_input';

export const createSession = (token: string, form: IFormFieldValues) => {
    return new Promise<ISubmitSuccessResponse>((resolve, reject) => {
        const url = API_HOST + '/api/view/web-token/submit';

        request(url, {
            method: 'POST',
            body: JSON.stringify({
                token,
                form
            })
        })
            .then(res => {
                const session = res as ISubmitSuccessResponse;
                Cookies.set('sessionid', session.sessionId);
                resolve(session);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const checkSessionStatus = (): Promise<ISessionStatus> => {
    const url = API_HOST + '/api/view/web-token/status';

    return new Promise((resolve, reject) => {
        request(url)
            .then(res => resolve(res as ISessionStatus))
            .catch(error => {
                reject(error);
            });
    });
};

export const notifyDeviceReady = () => {
    const url = API_HOST + '/api/view/web-token/ready';
    return request(url);
};
