/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import View from '../view';
import hangUpImg from '../../assets/hang_up.png';

const Done: React.FC<any> = () => {
    return (
        <View center>
            <div
                css={{
                    textAlign: 'center'
                }}
            >
                <img
                    css={{ height: 94, width: 'auto', marginBottom: 34 }}
                    src={hangUpImg}
                    alt='Hang-up'
                />

                <h1>
                    <FormattedMessage id='done_header' />
                </h1>
                <p css={{ marginBottom: 80 }}>
                    <FormattedMessage id='done_subheader' />
                </p>
                <span>
                    <FormattedMessage
                        id='done_read_more_link'
                        values={{
                            link: (...msg: string[]) => (
                                <a
                                    href='https://www.nhs.uk/conditions/coronavirus-covid-19/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {msg}
                                </a>
                            )
                        }}
                    />
                </span>
            </div>
        </View>
    );
};

export default Done;
