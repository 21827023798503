/** @jsx jsx */
import React, { useState, useContext, Fragment } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';
import {
    createSession,
    ISubmitErrorStatus,
    notifyDeviceReady
} from '../../api/api';
import { AppContext } from '../../context/appContext';

import { colors } from '../../constants/colors';
import signupForm from '../../models/signupForm';
import useForm, { IFormFieldValues } from '../../hooks/useForm';

import View from '../view';
import Input from '../common/input';
import Button from '../common/button';
import TocLinks from '../common/tocLinks';
import RequestError from '../../utils/RequestError';

type IViewState = 'DEFAULT' | 'PENDING' | 'ERROR';

const Signup: React.FC<any> = () => {
    const appContext = useContext(AppContext);
    const [viewState, setviewState] = useState<IViewState>('DEFAULT');

    const { formatMessage } = useIntl();
    const intl = (id: string | null) => {
        return id ? formatMessage({ id }) : undefined;
    };

    const submitDeviceReady = () => {
        notifyDeviceReady()
            .then(() => {
                appContext.pollSessionStatus();
            })
            .catch(() => {
                setTimeout(() => {
                    submitDeviceReady();
                }, 30000);
            });
    };

    const submitForm = (fieldValues: IFormFieldValues) => {
        setviewState('PENDING');

        createSession(fieldValues['token'], fieldValues)
            .then(() => {
                appContext.setView('WAITING');
                submitDeviceReady();
            })
            .catch((error: RequestError) => {
                switch (error.body as ISubmitErrorStatus) {
                    case 'token_consumed':
                    case 'token_not_found':
                    case 'token_expired':
                        appContext.setView('DONE');
                        break;
                    default:
                        setviewState('ERROR');
                }
            });
    };

    const {
        fieldValues,
        fieldErrors,
        handleOnChange,
        handleOnSubmit
    } = useForm(signupForm, submitForm);

    return (
        <View>
            <div
                css={{
                    fontSize: 15,
                    fontWeight: 700,
                    lineHeight: 1.2,
                    marginBottom: 26
                }}
            >
                {intl('signup_form_header')}
            </div>
            <form onSubmit={handleOnSubmit}>
                {signupForm.map((field, i) => {
                    return (
                        <Fragment key={i}>
                            {!field.hidden && (
                                <Input
                                    type={field.type}
                                    name={field.name}
                                    value={fieldValues[field.name]}
                                    label={intl(field.label)}
                                    onChange={handleOnChange}
                                    errorText={intl(fieldErrors[field.name])}
                                ></Input>
                            )}
                        </Fragment>
                    );
                })}
                {viewState === 'ERROR' && (
                    <div
                        css={{
                            color: colors.ALERT,
                            background: colors.ALERT_BG,
                            padding: '20px 20px',
                            fontWeight: 500
                        }}
                    >
                        {intl('signup_generic_error')}
                    </div>
                )}

                <TocLinks></TocLinks>

                <Button
                    type='submit'
                    disabled={viewState === 'PENDING'}
                    css={{ marginTop: 20 }}
                >
                    {intl('signup_submit_button')}
                </Button>
            </form>
        </View>
    );
};

export default Signup;
