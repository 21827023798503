/** @jsx jsx */
import React, { useContext, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';
import { AppContext } from '../../context/appContext';

import Player from '../common/player';
import Button from '../common/button';

import View from '../view';
import pickUpImg from '../../assets/pick_up.png';

const Meeting: React.FC<any> = () => {
    const appContext = useContext(AppContext);
    const [callAccepted, setCallAccepted] = useState(false);

    const twilioDisconnectHandler = () => {
        appContext.pollSessionStatus();
    };

    const acceptCallHandler = () => {
        setCallAccepted(true);
    };

    return (
        <View>
            {/* When the meeting starts, user is presented with an "accept call" button. */}
            {appContext.status.twilioSession && !callAccepted && (
                <div
                    css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        flexGrow: 1,
                        textAlign: 'center'
                    }}
                >
                    <div>
                        <img
                            css={{
                                height: 126,
                                width: 'auto',
                                marginBottom: 10
                            }}
                            src={pickUpImg}
                            alt='Pick-up'
                        />

                        <h1 css={{ marginBottom: 68 }}>
                            <FormattedMessage id='meeting_incoming_call_header' />
                        </h1>

                        <Button onClick={acceptCallHandler}>
                            <FormattedMessage id='meeting_accept_call_button' />
                        </Button>
                    </div>
                </div>
            )}

            {/* Upon accepting the call, everything else from the page is removed and we try
            to make the player take up as much space as possible.
            The video is also centered horizontally when viewed on a device with a narrow screen */}
            {appContext.status.twilioSession && callAccepted && (
                <div
                    css={{
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        backgroundColor: '#000',
                        height: '100vh',
                        overflow: 'hidden',
                        video: {
                            width: 'auto',
                            height: '100vh',
                            marginLeft: '50vw',
                            transform: 'translate(-50%)'
                        }
                    }}
                >
                    <Player
                        room={appContext.status.twilioSession.room}
                        token={appContext.status.twilioSession.token}
                        region={appContext.status.twilioSession.region}
                        disconnectHandler={twilioDisconnectHandler}
                    ></Player>
                </div>
            )}
        </View>
    );
};

export default Meeting;
