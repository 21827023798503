import { CSSObject } from '@emotion/core';
import { colors } from '../constants/colors';

const CSS_RESET: CSSObject = {
    'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, button, input, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video': {
        margin: 0,
        padding: 0,
        border: 0,
        fontSize: '100%',
        font: 'inherit',
        verticalAlign: 'baseline',
        textDecoration: 'none'
    }
};

const GLOBAL_STYLE: CSSObject = {
    body: {
        fontFamily: `'Montserrat','Helvetica Neue',Helvetica,Arial,sans-serif`,
        fontSize: 15,
        lineHeight: '24px',
        color: '#000'
    },

    h1: {
        fontSize: 20,
        fontWeight: 700,
        marginBottom: 10
    },

    a: {
        color: colors.SKY_BLUE,
        whiteSpace: 'nowrap'
    }
};

export default { ...CSS_RESET, ...GLOBAL_STYLE };
