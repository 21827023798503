import { camelizeKeys } from 'humps';
import RequestError from './RequestError';
import platform from 'platform';

const DEFAULT_HEADERS: Record<string, string> = {
    'X-Client-Country': 'GB',
    'X-Client-ID': 'kry-android',
    'X-Client-Bundle-ID': 'se.kry.android',
    'X-Client-Language': 'en',
    'X-System-Version': platform.description?.substring(0, 32) || 'web',
    'X-System-Language': navigator.language,
    'X-Client-Version': '624'
};

const DEFAULT_CREDENTIALS = 'include';

interface IRequestOptions {
    headers?: Record<string, string>;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | string;
    body?: string;
    credentials?: 'include' | 'omit' | 'same-origin';
}

const request = (
    url: RequestInfo,
    options: IRequestOptions = {}
): Promise<unknown | string> => {
    // Append default headers and credentials
    options.headers = { ...DEFAULT_HEADERS, ...options.headers };
    options.credentials = options.credentials || DEFAULT_CREDENTIALS;

    const parseBody = (response: Response): Promise<unknown | string> => {
        const contentType = response.headers.get('content-type');
        return typeof contentType === 'string' &&
            contentType.includes('application/json')
            ? response.json().then(json => {
                  return camelizeKeys(json);
              })
            : response.text();
    };

    return fetch(url, options).then(response =>
        parseBody(response).then(body => {
            if (response.ok) return body;

            const message = (body as any).error || response.statusText;

            return Promise.reject(
                new RequestError(message, response.status, response, body)
            );
        })
    );
};

export default request;
