export default class RequestError extends Error {
    status: number;
    response: Response;
    body: unknown;

    constructor(
        message: string,
        status: number,
        response: Response,
        body: unknown
    ) {
        super(message);
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, RequestError);
        }

        this.status = status;
        this.response = response;
        this.body = body;
    }
}
