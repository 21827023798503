/** @jsx jsx */
import React, { useContext, Fragment } from 'react';
import { jsx } from '@emotion/core';
import { AppContext } from './context/appContext';

// Views
import Meeting from './components/views/meeting';
import Intro from './components/views/intro';
import Signup from './components/views/signup';
import Done from './components/views/done';
import UnsupportedBrowser from './components/views/unsupportedBrowser';
import Waiting from './components/views/waiting';

const App: React.FC<any> = () => {
    const appContext = useContext(AppContext);
    const isProd = window.location.href.includes('nhs.livi.co.uk');

    const views: Record<string, React.FC<any>> = {
        INTRO: Intro,
        SIGNUP: Signup,
        WAITING: Waiting,
        MEETING: Meeting,
        DONE: Done,
        UNSUPPORTED: UnsupportedBrowser
    };

    const ViewComponent = views[appContext.view] || Intro;

    return (
        <Fragment>
            {!isProd && appContext.view !== 'INIT' && <ViewComponent />}

            {isProd && (
                <div
                    css={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100vh'
                    }}
                >
                    Under construction
                </div>
            )}
        </Fragment>
    );
};

export default App;
