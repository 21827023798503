import { IFormModel } from '../hooks/useForm';

const token = window.location.pathname.substring(1);

const signupForm: IFormModel = [
    {
        name: 'full_name',
        type: 'text',
        label: 'signup_field_fullname',
        validators: [
            {
                pattern: /^(?!\s*$).+/,
                errorMessage: 'signup_validation_empty'
            }
        ]
    },
    {
        name: 'dob',
        type: 'number',
        label: 'signup_field_dob',
        validators: [
            {
                pattern: /^(?!\s*$).+/,
                errorMessage: 'signup_validation_empty'
            },
            {
                pattern: /^\d{4}\d{2}\d{2}$/,
                errorMessage: 'signup_validation_date'
            }
        ]
    },
    {
        name: 'post_code',
        type: 'text',
        label: 'signup_field_postcode',
        validators: [
            {
                pattern: /^(?!\s*$).+/,
                errorMessage: 'signup_validation_empty'
            }
        ]
    },
    {
        name: 'address',
        type: 'text',
        label: 'signup_field_address',
        validators: [
            {
                pattern: /^(?!\s*$).+/,
                errorMessage: 'signup_validation_empty'
            }
        ]
    },
    {
        name: 'gp_name',
        type: 'text',
        label: 'signup_field_gp_name',
        validators: [
            {
                pattern: /^(?!\s*$).+/,
                errorMessage: 'signup_validation_empty'
            }
        ]
    },
    {
        name: 'practice_name',
        type: 'text',
        label: 'signup_field_practice_name',
        validators: [
            {
                pattern: /^(?!\s*$).+/,
                errorMessage: 'signup_validation_empty'
            }
        ]
    },
    {
        name: 'token',
        type: 'text',
        label: 'signup_field_token',
        initialValue: token || '',
        hidden: !!token,
        validators: [
            {
                pattern: /^(?!\s*$).+/,
                errorMessage: 'signup_validation_empty'
            }
        ]
    }
];

export default signupForm;
