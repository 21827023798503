import React, { useEffect, useRef } from 'react';
import {
    connect as connectToRoom,
    createLocalTracks,
    Participant,
    ConnectOptions,
    Room,
    LocalTrack,
    Track,
    LocalVideoTrack,
    LocalAudioTrack
} from 'twilio-video';

interface TwilioVideoPlayerArgument {
    roomName: string;
    region: ConnectOptions['region'];
    token: string;
    disconnectHandler: () => void;

    disableLocalParticipantVideo?: boolean;
    disableLocalParticipantAudio?: boolean;
}

const useTwilioVideoPlayer = ({
    roomName,
    region,
    token,
    disconnectHandler,

    disableLocalParticipantVideo,
    disableLocalParticipantAudio
}: TwilioVideoPlayerArgument) => {
    const roomRef: React.MutableRefObject<Room | null> = useRef<Room>(null);
    const localTracksRef: React.MutableRefObject<LocalTrack[] | null> = useRef<
        LocalTrack[]
    >(null);

    const localTrackNodeRef: React.MutableRefObject<HTMLElement | null> = useRef(
        null
    );
    const remoteTrackNodeRef: React.MutableRefObject<HTMLElement | null> = useRef(
        null
    );

    const log = (...args: any[]) => {
        if (true) return;
        console.log(...args); // eslint-disable-line
    };

    useEffect(() => {
        let didCancel = false;

        const disconnectFromRoom = () => {
            didCancel = true;

            const room = roomRef.current;
            if (room) {
                room.disconnect();
                roomRef.current = null;
            }

            const localTracks = localTracksRef.current;
            if (localTracks) {
                localTracks
                    .filter(({ kind }) => ['video', 'audio'].includes(kind))
                    // @ts-ignore
                    .forEach(track => track.stop());
                localTracksRef.current = null;
            }
        };

        createLocalTracks({
            audio: true,
            video: {}
        }).then(localTracks => {
            localTracksRef.current = localTracks;
            if (didCancel) {
                log('already disconnecting, closing new localTracks');
                disconnectFromRoom();
                return;
            }

            localTracks.forEach(track => {
                if (track.kind === 'video') {
                    track.on('dimensionsChanged', log);
                }

                track.on('disabled', log);
                track.on('enabled', log);
                track.on('started', track => {
                    log('Local track started', track);
                    if (track.kind !== 'video') return;
                    // onLocalParticipantConnected({ dimensions: track.dimensions });
                });
                track.on('stopped', log);

                if (!localTrackNodeRef.current)
                    throw new Error('Local track container node not set');

                // @ts-ignore
                localTrackNodeRef.current.appendChild(track.attach());
            });

            const filteredLocalTracks = localTracks.filter(t => {
                switch (t.kind) {
                    case 'video':
                        return !disableLocalParticipantVideo;
                    case 'audio':
                        return !disableLocalParticipantAudio;
                    default:
                        return true;
                }
            });

            connectToRoom(token, {
                name: roomName,
                tracks: filteredLocalTracks,
                region
            }).then(
                (room: Room) => {
                    roomRef.current = room;
                    log('connected to room');

                    if (didCancel) {
                        log('already disconnecting, closing new room');
                        disconnectFromRoom();
                        return;
                    }

                    // onConnectedToRoom();

                    const createRemoteParticipantTrackSubscribedHandler = (
                        participantIdentity: Participant
                    ) => (track: Track) => {
                        log(
                            'participant track subscribed',
                            participantIdentity,
                            track
                        );

                        if (track.kind === 'data') return;

                        if (track.kind === 'video') {
                            // onStreamPropertyChange(participantIdentity, {
                            //   property: 'hasVideo',
                            //   value: true
                            // });
                            // onStreamPropertyChange(participantIdentity, {
                            //   property: 'videoDimensions',
                            //   value: track.dimensions
                            // });
                        }

                        if (!remoteTrackNodeRef.current)
                            throw new Error(
                                'Remote track container node not set'
                            );

                        // @ts-ignore
                        remoteTrackNodeRef.current.appendChild(track.attach());
                    };

                    const setupRemoteParticipant = (
                        participant: Participant
                    ) => {
                        // onRemoteParticipantConnected(participant);

                        participant.tracks.forEach(publication => {
                            publication.on('trackDisabled', log);
                            publication.on('trackEnabled', log);

                            if (
                                // @ts-ignore
                                !publication.isSubscribed ||
                                // @ts-ignore
                                publication.kind === 'data'
                            )
                                return;

                            if (!remoteTrackNodeRef.current)
                                throw new Error(
                                    'Remote track container node not set'
                                );

                            remoteTrackNodeRef.current.appendChild(
                                // @ts-ignore
                                publication.track.attach()
                            );
                        });

                        participant.on(
                            'trackSubscribed',
                            createRemoteParticipantTrackSubscribedHandler(
                                // @ts-ignore
                                participant.identity
                            )
                        );

                        participant.on('trackUnsubscribed', (track: Track) => {
                            log('participant track unsubscribed', track);
                            if (track.kind === 'data') return;
                            // @ts-ignore
                            const attachedElements = track.detach();
                            attachedElements.forEach(
                                (element: HTMLMediaElement) => element.remove()
                            );
                            log('track detached', track);
                        });

                        participant.on('disconnected', (...args) => {
                            log(...args);
                            // onRemoteParticipantDisconnected(participant.identity);
                        });
                        participant.on('networkQualityLevelChanged', log);
                        participant.on('trackDimensionsChanged', log);
                        participant.on('trackDisabled', log);
                        participant.on('trackEnabled', log);
                        participant.on('trackMessage', log);
                        participant.on('trackPublished', log);
                        participant.on('trackUnpublished', log);
                        participant.on('trackStarted', log);
                    };

                    // Attach the tracks of the room's already connected participants.
                    room.participants.forEach(setupRemoteParticipant);

                    room.localParticipant.on('error', e =>
                        log('local participant error', e)
                    );
                    room.localParticipant.on('disconnected', (e, a) => {
                        log('local participant disconnected', e, a);
                        // checkStreamDestroyedReason();
                    });
                    room.localParticipant.on('networkQualityLevelChanged', log);
                    room.localParticipant.on('trackPublished', log);
                    room.localParticipant.on('trackPublicationFailed', log);

                    // When a Participant adds a Track, attach it to the DOM.
                    room.on('trackAdded', () => log('track added'));
                    room.on('trackRemoved', () => log('track removed'));

                    room.on('participantConnected', setupRemoteParticipant);

                    // When a Participant leaves the Room, detach its Tracks.
                    room.on('participantDisconnected', p => {
                        log('Participant disconnected', p);
                        p.tracks.forEach((publication: any) => {
                            if (
                                !publication.isSubscribed ||
                                publication.kind === 'data'
                            )
                                return;
                            const attachedElements = publication.track.detach();
                            attachedElements.forEach(
                                (element: HTMLMediaElement) => element.remove()
                            );
                            log('track detached', publication);
                        });
                    });

                    room.on('error', (error: any) => log('room error', error));

                    room.on('disconnected', room => {
                        room.localParticipant.tracks.forEach(
                            (publication: any) => {
                                publication.track.stop();
                                const attachedElements = publication.track.detach();
                                attachedElements.forEach(
                                    (element: HTMLMediaElement) =>
                                        element.remove()
                                );
                            }
                        );
                        // Notify parent about stream being destroyed to cancel visuals
                        // onStreamDestroyed();
                        disconnectHandler();
                    });
                },
                error => log('Error connecting to room', error)
            );
        });

        window.addEventListener('beforeunload', disconnectFromRoom);

        return disconnectFromRoom;
    }, [
        roomName,
        token,
        disableLocalParticipantAudio,
        disableLocalParticipantVideo,
        region,
        disconnectHandler
    ]);

    useEffect(() => {
        const { current: room } = roomRef;
        const { current: localTracks } = localTracksRef;

        if (!room) return;

        if (!disableLocalParticipantVideo) {
            // This should never happen
            if (!Array.isArray(localTracks))
                throw new Error('Missing local participant tracks');

            // Publish local video track
            const localVideoTrack = localTracks.find(
                track => track.kind === 'video'
            );
            room.localParticipant.publishTrack(
                localVideoTrack as LocalVideoTrack
            );
            return;
        }

        // Unpublish local video track
        room.localParticipant.videoTracks.forEach(p => p.unpublish());
    }, [disableLocalParticipantVideo]);

    useEffect(() => {
        const { current: room } = roomRef;
        const { current: localTracks } = localTracksRef;

        if (!room) return;

        // This should never happen
        if (!Array.isArray(localTracks))
            throw new Error('Missing local participant tracks');

        if (!disableLocalParticipantAudio) {
            // Publish local audio track
            const localAudioTrack = localTracks.find(
                track => track.kind === 'audio'
            );
            room.localParticipant.publishTrack(
                localAudioTrack as LocalAudioTrack
            );
            return;
        }

        // Unpublish local audio track
        room.localParticipant.audioTracks.forEach(p => p.unpublish());
    }, [disableLocalParticipantAudio]);

    return {
        setLocalTracksContainerNode: (node: any) => {
            localTrackNodeRef.current = node;
        },
        setRemoteTracksContainerNode: (node: any) => {
            remoteTrackNodeRef.current = node;
        }
    };
};

interface VideoPlayerProps {
    room: string;
    token: string;
    region: ConnectOptions['region'];
    disconnectHandler: () => void;
}

const Player: React.FC<VideoPlayerProps> = ({
    token,
    room,
    region,
    disconnectHandler
}) => {
    const {
        setLocalTracksContainerNode,
        setRemoteTracksContainerNode
    } = useTwilioVideoPlayer({
        region,
        roomName: room,
        token,
        disconnectHandler
    });
    return (
        <>
            <div ref={setRemoteTracksContainerNode} />
            <div ref={setLocalTracksContainerNode} />
        </>
    );
};

export default Player;
