import React, {
    createContext,
    useState,
    ReactNode,
    useEffect,
    Dispatch,
    SetStateAction
} from 'react';
import { checkSessionStatus } from '../api/api';
import { ConnectOptions } from 'twilio-video';
import RequestError from '../utils/RequestError';

interface IAppContext {
    view: IView;
    status: ISessionStatus;
    meetingStarted: boolean;

    setView: Dispatch<SetStateAction<IView>>;
    setStatus: Dispatch<SetStateAction<ISessionStatus>>;
    setMeetingStarted: Dispatch<SetStateAction<boolean>>;

    pollSessionStatus: () => void;
}

export const AppContext = createContext({} as IAppContext);

type IView =
    | 'INIT'
    | 'INTRO'
    | 'SIGNUP'
    | 'WAITING'
    | 'MEETING'
    | 'DONE'
    | 'UNSUPPORTED';

export interface ISessionStatus {
    type: 'EMPTY' | 'WAITING' | 'MEETING';
    waitingTimeInMinutes?: number;
    twilioSession?: {
        room: string;
        token: string;
        region?: ConnectOptions['region'];
    };
}

export const AppContextProvider: React.FC<ReactNode> = ({ children }) => {
    const [view, setView] = useState<IView>('INIT');
    const [status, setStatus] = useState<ISessionStatus>({ type: 'EMPTY' });

    const [meetingStarted, setMeetingStarted] = useState<boolean>(() => {
        let storedState;
        try {
            storedState = JSON.parse(localStorage.getItem('appState') || '');
        } catch {}
        return storedState?.meetingStarted || false;
    });

    const pollSessionStatus = () => {
        checkSessionStatus()
            .then(data => {
                setStatus(data);

                // If a meeting has started, update the session status
                // and abort polling, as it will be resumed upon Twilio disconnect.
                if (data.type === 'MEETING') {
                    setMeetingStarted(true);
                    setView('MEETING');
                    return;
                }

                // If the session status is EMPTY and the meeting has already
                // been started once, navigate to the Done view, but keep
                // polling the session status.
                if (data.type === 'EMPTY') {
                    if (meetingStarted) {
                        setView('DONE');
                    } else {
                        setView('WAITING');
                    }
                }

                // If the session status is WAITING, navigate to the waiting view
                // and keep polling the session status.
                if (data.type === 'WAITING') {
                    setView('WAITING');
                }

                setTimeout(() => {
                    pollSessionStatus();
                }, 30000);
            })
            .catch((error: RequestError) => {
                console.dir(error);
                // If the session status request fails during startup of the app,
                //or due to the user not beeing looged in,
                // reset the state and go to the intro view.
                if (view === 'INIT' || error.status === 401) {
                    setView('INTRO');
                    setStatus({ type: 'EMPTY' });
                    setMeetingStarted(false);
                    return;
                }

                // For whatever else reason, set the default EMPTY status and keep polling.
                setStatus({ type: 'EMPTY' });
                setView('WAITING');
                setTimeout(() => {
                    pollSessionStatus();
                }, 30000);
            });
    };

    // Runs on app start. Start polling the session status. If user is not logged in,
    // the state will be reset and the polling cancelled.
    useEffect(() => {
        pollSessionStatus();
    }, []);

    // Save parts of state to local storage when updated.
    useEffect(() => {
        localStorage.setItem('appState', JSON.stringify({ meetingStarted }));
    }, [meetingStarted]);

    const initialContext = {
        view,
        status,
        meetingStarted,

        setView,
        setStatus,
        setMeetingStarted,

        pollSessionStatus
    };

    return (
        <AppContext.Provider value={initialContext}>
            {children}
        </AppContext.Provider>
    );
};
