/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import View from '../view';
import errorImg from '../../assets/error.png';

const UnsupportedBrowser: React.FC<any> = () => {
    return (
        <View center>
            <div css={{ textAlign: 'center' }}>
                <img
                    css={{ height: 132, width: 'auto', marginBottom: 34 }}
                    src={errorImg}
                    alt='KRY intro'
                />

                <div>
                    <h1>
                        <FormattedMessage id='unsupported_browser_header' />
                    </h1>
                    <p>
                        <FormattedMessage id='unsupported_browser_subheader' />
                    </p>
                </div>
            </div>
        </View>
    );
};

export default UnsupportedBrowser;
