/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';
import { AppContext } from '../../context/appContext';
import Button from '../common/button';
import isWebRTCSupported from '../../utils/browserSupport';

import View from '../view';
import doctorImg from '../../assets/physician.png';

const Intro: React.FC<any> = () => {
    const appContext = useContext(AppContext);

    return (
        <View center>
            <div
                css={{
                    textAlign: 'center',
                    padding: '0 20px'
                }}
            >
                <img
                    css={{ height: 200, width: 'auto', marginBottom: 20 }}
                    src={doctorImg}
                    alt='LIVI physician'
                />

                <div css={{ marginBottom: 50 }}>
                    <h1>
                        <FormattedMessage id='intro_header' />
                    </h1>
                    <p>
                        <FormattedMessage id='intro_subheader' />
                    </p>
                </div>

                <Button
                    onClick={() => {
                        if (isWebRTCSupported()) {
                            appContext.setView('SIGNUP');
                        } else {
                            appContext.setView('UNSUPPORTED');
                        }
                    }}
                >
                    <FormattedMessage id='intro_start_button' />
                </Button>
            </div>
        </View>
    );
};

export default Intro;
