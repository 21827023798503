/** @jsx jsx */
import React, { FC } from 'react';
import { jsx, CSSObject } from '@emotion/core';

import nhsLogo from '../../assets/nhs_logo.png';

const BASE_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'fixed',
    width: '100%',
    height: 70,
    backgroundColor: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)'
};

const Header: React.FC<any> = () => {
    return (
        <header css={BASE_STYLE}>
            <img
                css={{ height: 32, marginRight: 20 }}
                src={nhsLogo}
                alt='NHS logo'
            />
        </header>
    );
};

export default Header;
