/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';
import { colors } from '../../constants/colors';

const TocLinks: React.FC<any> = () => {
    return (
        <div
            css={{
                fontSize: 13,
                fontWeight: 500,
                margin: '12px 0 0',
                padding: '0 0 15px',
                textAlign: 'center',
                color: colors.DARK_GREY,
                borderBottom: `1px solid ${colors.LIGHT_GREY}`,
                lineHeight: 1.3
            }}
        >
            <FormattedMessage
                id='signup_terms_toc_links'
                values={{
                    tocLink: (...msg: string[]) => (
                        <a
                            href='https://www.livi.co.uk/meet-tos/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {msg}
                        </a>
                    ),
                    privacyLink: (...msg: string[]) => (
                        <a
                            href='https://www.livi.co.uk/meet-pp/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {msg}
                        </a>
                    )
                }}
            />
        </div>
    );
};

export default TocLinks;
