/** @jsx jsx */
import React, { FC } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { colors } from '../../constants/colors';

interface IProps extends React.HTMLProps<HTMLInputElement> {
    errorText?: string;
}

const Input: FC<IProps> = ({ label, errorText, className, ...props }) => {
    const INPUT_STYLE: CSSObject = {
        fontSize: 17,
        fontWeight: 500,
        margin: '-4px 0 20px',
        height: 22,
        padding: '8px 0 8px',
        outline: 'none',
        borderStyle: 'none none solid',
        borderBottom: `2px solid ${
            errorText ? colors.ALERT : colors.LIGHT_GREY
        }`,
        borderRadius: 0,
        ':focus': {
            borderBottom: `2px solid ${colors.PRIMARY}`
        },
        '::placeholder': {
            color: colors.LIGHT_GREY
        },
        ':-webkit-autofill': {
            boxShadow: '0 0 0 20px #fff inset'
        },
        appearance: 'none',
        MozAppearance: 'none',
        WebkitAppearance: 'none'
    };

    const LABEL_STYLE: CSSObject = {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 12,
        color: colors.DARK_GREY,
        textTransform: 'uppercase'
    };

    const ERROR_STYLE: CSSObject = {
        fontSize: 15,
        fontWeight: 500,
        color: colors.ALERT,
        margin: '-14px 0 14px',
        textTransform: 'none'
    };

    return (
        <label css={LABEL_STYLE} className={className}>
            {label}
            <input {...props} css={INPUT_STYLE}></input>
            {errorText && <div css={ERROR_STYLE}>{errorText}</div>}
        </label>
    );
};

export default Input;
