import {
    useState,
    FormEvent,
    InputHTMLAttributes,
    useEffect,
    useCallback
} from 'react';

export interface IFormFieldValues {
    [fieldName: string]: string;
}

interface IFormFieldErrors {
    [fieldName: string]: string | null;
}

export interface IFormModel
    extends Array<{
        name: string;
        type: InputHTMLAttributes<HTMLInputElement>['type'];
        initialValue?: string;
        label: string;
        hidden?: boolean;
        validators?: Array<{
            pattern: RegExp;
            errorMessage: string;
        }>;
    }> {}

const useForm = (
    formModel: IFormModel,
    submitFunction: (fieldValues: IFormFieldValues) => void
) => {
    const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [fieldErrors, setFieldErrors] = useState<IFormFieldErrors>({});
    const [fieldValues, setFieldValues] = useState<IFormFieldValues>(() => {
        const initalValues: IFormFieldValues = {};

        formModel.forEach(
            field => (initalValues[field.name] = field.initialValue || '')
        );

        return initalValues;
    });

    const handleOnChange = (e: FormEvent<HTMLInputElement>) => {
        setFieldValues({
            ...fieldValues,
            [e.currentTarget.name]: e.currentTarget.value
        });
    };

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setFormIsDirty(true);
        setIsSubmitting(true);
        setFieldErrors(getFormErrors());
    };

    const submitCallback = useCallback(submitFunction, []);

    const getFormErrors = useCallback((): IFormFieldErrors => {
        const errors: IFormFieldErrors = {};

        formModel.forEach(field => {
            if (!field.hidden && field.validators) {
                for (const validation of field.validators) {
                    if (!validation.pattern.test(fieldValues[field.name])) {
                        errors[field.name] = validation.errorMessage;
                        break;
                    }
                }
            }
        });

        return errors;
    }, [formModel, fieldValues]);

    // If the form has been submitted, validate fields in realtime.
    useEffect(() => {
        if (formIsDirty) {
            setFieldErrors(getFormErrors());
        }
    }, [fieldValues, formIsDirty, getFormErrors]);

    // If there are no errors and the form is submitting,
    // execure the callback function.
    useEffect(() => {
        if (Object.keys(fieldErrors).length === 0 && isSubmitting) {
            submitCallback(fieldValues);
        }

        setIsSubmitting(false);
    }, [fieldErrors, fieldValues, isSubmitting, submitCallback]);

    return { fieldValues, fieldErrors, handleOnChange, handleOnSubmit };
};
export default useForm;
