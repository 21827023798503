export const colors = {
    PRIMARY: '#28A494',
    SECONDARY: '#EBF8F2',

    SKY_BLUE: '#3080ed',

    LIGHT_GREY: '#E9ECF0',
    MEDIUM_GREY: '#AEAEAE',
    DARK_GREY: '#7e7e7e',

    ALERT: '#BE2424',
    ALERT_BG: '#FFE4E4'
};
