/** @jsx jsx */
import React, { Fragment, useContext } from 'react';
import { jsx } from '@emotion/core';

import { FormattedMessage } from 'react-intl';
import { AppContext } from '../../context/appContext';
import { colors } from '../../constants/colors';

import View from '../view';
import videoImg from '../../assets/video.png';

const Waiting: React.FC<any> = () => {
    const appContext = useContext(AppContext);

    const renderWaitingTime = (waitingTime?: number) => {
        if (waitingTime !== undefined) {
            switch (waitingTime) {
                case -1:
                    return <FormattedMessage id='meeting_countdown_unknown' />;
                case 0:
                case 1:
                    return (
                        <Fragment>
                            <FormattedMessage id='meeting_countdown_header' />
                            <div css={{ fontWeight: 700 }}>
                                <FormattedMessage id='meeting_countdown_shortly' />
                            </div>
                        </Fragment>
                    );
                default:
                    return (
                        <Fragment>
                            <FormattedMessage id='meeting_countdown_header' />
                            <div css={{ fontWeight: 700 }}>
                                <FormattedMessage
                                    id='meeting_countdown_minutes'
                                    values={{ minutes: waitingTime }}
                                />
                            </div>
                        </Fragment>
                    );
            }
        }
    };

    return (
        <View>
            {/* If we are in any other session status then ongoing meeting, we show information heading
            as well as a countown, if applicable. */}
            <div css={{ textAlign: 'center' }}>
                <Fragment>
                    {/* The heading giving an introduction to the meeting and breef instructions */}
                    <div css={{ padding: '40px 20px 0', marginBottom: 50 }}>
                        <h1>
                            <FormattedMessage id='meeting_header' />
                        </h1>
                        <p>
                            <FormattedMessage id='meeting_subheader' />
                        </p>
                    </div>

                    {/* The container reflecting the status of the session, up until the meeting starts.  */}
                    <div
                        css={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: colors.LIGHT_GREY,
                            margin: '0 -20px',
                            height: 360,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img
                            css={{
                                height: 94,
                                width: 'auto',
                                marginBottom: 18
                            }}
                            src={videoImg}
                            alt='Video'
                        />

                        {appContext.status.type === 'EMPTY' && (
                            <FormattedMessage id='meeting_connecting' />
                        )}

                        {appContext.status.type === 'WAITING' && (
                            <Fragment>
                                {renderWaitingTime(
                                    appContext.status.waitingTimeInMinutes
                                )}
                            </Fragment>
                        )}
                    </div>
                </Fragment>
            </div>
        </View>
    );
};

export default Waiting;
