/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx } from '@emotion/core';
import Div100vh from 'react-div-100vh';
import Header from './common/header';

interface Iprops {
    center?: boolean;
}

const View: React.FC<Iprops> = ({ children, ...props }) => {
    return (
        <Fragment>
            <Header></Header>

            {/* This components solves the issue with 100vh on mobile clients
            (where it doesn't properly change depending on the toolbar visibility etc),
            https://www.npmjs.com/package/react-div-100vh */}
            <Div100vh
                as='main'
                style={{ minHeight: '100rvh' }}
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: props.center ? 'center' : 'flex-start',
                    width: '100%',
                    maxWidth: 500,
                    margin: '0 auto',
                    boxSizing: 'border-box',
                    padding: '90px 20px 20px 20px'
                }}
            >
                {children}
            </Div100vh>
        </Fragment>
    );
};

export default View;
